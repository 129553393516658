<template>
  <div class="wrapper">
    <h1 class="mb-20">Пользователи</h1>

    <div class="between ai-c mb-30">
      <div class="d-flex gap-xxs">
        <VButton
          size="menu"
          icon="filter"
          color="primary-sm"
          :active="isActiveFilter"
          @click.native="isActiveFilter = !isActiveFilter"
        />

        <VSearch
          placeholder="Поиск по ФИО"
          @request="loadSearch"
        />
      </div>

      <VButton
        text="Создать"
        icon="create"
        size="sm"
        color="primary-sm"
        @click.native="$router.push({ name: 'CreateUser' })"
      />
    </div>

    <!--  TODO FILTER  -->
    <!--  Form filter  -->
    <transition name="fade">
      <VForm
        v-if="isActiveFilter"
        class="form-filter mb-20"
        ref="filterFormData"
        @request="filterHandler"
      >
        <div class="grid-filter mb-30">
          <VSelect
            label="Должность:"
            name="roles"
            multiple
            item-text="name_translate"
            :options="userRoles"
          />
        </div>

        <div class="buttons">
          <VButton
            text="Применить"
            type="submit"
            color="primary"
          />

          <VButton
            text="Сбросить"
            @click.native="filterReset"
          />
        </div>
      </VForm>
    </transition>
    <!--  end Form filter  -->

    <Spinner v-if="isLoading" />

    <div v-if="!isLoading && list.length" class="user-list pb-30">
      <APCard
        v-for="user in list"
        :key="user.id"
        :title="getUserFullName(user)"
        has-detail
        @open-detail="goToPage('DetailUser', { id: user.id })"
        @update="goToPage('UpdateUser', { id: user.id })"
        @remove="openModalDelete(user.id)"
      >
        <AppInfoTextBlock
          v-if="user.email"
          label-text="Email"
          :text="user.email"
        />
      </APCard>
    </div>

    <h2 v-if="!list.length && !isLoading">
      Ничего не найдено
    </h2>

    <AppPagination
      v-if="pagination.count > 1"
      :current-page="pagination.page"
      :page-count="pagination.count"
      @setPage="loadPage"
    />

    <ModalConfirmation
      v-if="isModal"
      title="Вы действительно хотите удалить данного пользователя?"
      @confirm="deleteUserHandler"
      @reject="closeModalDelete"
    />
  </div>
</template>

<script>
import ModalConfirmation from '@/components/ui/Modal/ModalConfirmation'
import AppInfoTextBlock from '@/components/ui/AppInfoTextBlock'
import AppPagination from '@/components/ui/AppPagination.vue'
import APCard from '@/components/views/AdminPanel/APCard'
import VForm from '@/components/Form/VForm'
import VButton from '@/components/simple/button/VButton'
import VSearch from '@/components/Form/Search'
import VSelect from '@/components/Form/VSelect'

import { mapActions, mapState } from 'vuex'
import getStringValuesArray from '@/utils/array/getStringValuesArray'
import { getUserFullName } from '@/utils/model/User'
import { goToPage } from '@/utils/router'
import { mixinModal } from '@/mixins/modal/mixinModal'

export default {
  name: 'Users',
  components: {
    VSearch,
    AppInfoTextBlock,
    AppPagination,
    APCard,
    VForm,
    ModalConfirmation,
    VButton,
    VSelect
  },

  mixins: [mixinModal],
  data () {
    return {
      idToDelete: null,
      isActiveFilter: false
    }
  },

  computed: {
    ...mapState('handbook/userRole', {
      userRoles: 'userRoles'
    }),
    ...mapState('users', {
      list: 'list',
      isLoading: 'isLoading',
      pagination: 'pagination'
    })
  },

  mounted () {
    this.loadList()
  },

  destroyed () {
    this.idToDelete = null
  },

  methods: {
    ...mapActions('users', {
      loadList: 'loadList',
      userDeactivate: 'userDeactivate',
      loadPage: 'loadPage',
      loadFilter: 'loadFilter',
      loadSearch: 'loadSearch'
    }),
    getUserFullName,
    goToPage,

    async deleteUserHandler () {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')
        const res = await this.userDeactivate(this.idToDelete)
        if (res?.status !== 204) return

        this.closeModalDelete()
        this.loadPage(1)
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
      }
    },
    openModalDelete (id) {
      this.idToDelete = id
      this.openModal()
    },
    closeModalDelete () {
      this.idToDelete = null
      this.closeModal()
    },

    filterHandler (data) {
      const filter = {
        roles: getStringValuesArray(data.roles)
      }

      this.loadFilter(filter)
    },
    filterReset () {
      this.$refs.filterFormData.clear()
      this.loadFilter()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.user-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
