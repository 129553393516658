<template>
  <VForm @request="onSubmit" class="form-search">
    <VInput
      :placeholder="placeholder"
      name="search"
      type="search"
      width="460px"
    />
  </VForm>
</template>

<script>
import VForm from '@/components/Form/VForm'
import VInput from '@/components/Form/Vinput/VInput'
export default {
  name: 'VSearch',
  components: {
    VForm,
    VInput
  },
  props: {
    placeholder: {
      type: String,
      required: true
    }
  },
  methods: {
    onSubmit ({ search }) {
      this.$emit('request', search)
    }
  }
}
</script>
